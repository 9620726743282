<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <!-- <span class="">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="72" />
        </span> -->
        <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> -->
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div *ngIf="project == 'primary'" class="navbar-header ml-5"  style="flex: 0 0 60%;">
  <div class="d-flex">
  <div class="nav navbar-nav flex-row header_Name">
    <a class="navbar-brand" routerLinkActive="active-link" routerLink="/dashboard/home">
      <span class="brand-logo">
        Home
      </span>
    </a>
  </div>
  <div class="nav navbar-nav flex-row header_Name" *ngIf = "roleId ==  2 || roleId == 3 || roleId == 0">
    <a class="navbar-brand" routerLinkActive="active-link" routerLink="/dashboard/primary-freight/gcpl-dashboard">
      <span class="brand-logo">
        My Approvals
      </span>
    </a>
  </div>
  <div class="nav navbar-nav flex-row header_Name" *ngIf = "roleId == 1">
    <a class="navbar-brand" routerLinkActive="active-link" routerLink="/apps/transactions/primary-freight/my-shipments">
      <span class="brand-logo">
        Primary - Transporter (Shipment Invoice)
      </span>
    </a>
  </div>
  <div class="nav navbar-nav flex-row header_Name" *ngIf = "roleId == 1">
    <a class="navbar-brand" routerLinkActive="active-link" routerLink="/apps/transactions/primary-freight/non-initiator-form">
      <span class="brand-logo">
        Primary - Transporter (Non Shipment Invoice)
      </span>
    </a>
  </div>
  <div class="nav navbar-nav flex-row header_Name" *ngIf = "roleId ==  2 || roleId ==3 || roleId == 0 || roleId == 4">
    <a class="navbar-brand" routerLinkActive="active-link" routerLink="/apps/reports/report">
      <span class="brand-logo">
        Reports
      </span>
    </a>
  </div>
  <div class="nav navbar-nav flex-row header_Name" *ngIf = "isAdmin == 1">
    <a class="navbar-brand" routerLinkActive="active-link" routerLink="dashboard/primary-freight/admin-master">
      <span class="brand-logo">
        Admin Masters
      </span>
    </a>
  </div>
  </div>
</div>

<div *ngIf="project == 'cfa'" class="navbar-header ml-5"  style="flex: 0 0 60%;">
  <div class="d-flex">
  <div class="nav navbar-nav flex-row header_Name">
    <a class="navbar-brand" routerLinkActive="active-link" routerLink="/dashboard/home">
      <span class="brand-logo">
        Home
      </span>
    </a>
  </div>
  <div class="nav navbar-nav flex-row header_Name" *ngIf = "roleId ==  1">
    <a class="navbar-brand" routerLinkActive="active-link" routerLink="/dashboard/cfa-expense/wh-or-rent">
      <span class="brand-logo">
        CFAExpense - My Approvals
      </span>
    </a>
  </div>
  <div class="nav navbar-nav flex-row header_Name" *ngIf = "roleId ==  2 || roleId == 3 || roleId == 4">
    <a class="navbar-brand" routerLinkActive="active-link" routerLink="/dashboard/cfa-expense/gcpl-dashboard">
      <span class="brand-logo">
        CFAExpense - My Approvals
      </span>
    </a>
  </div>
  <div class="nav navbar-nav flex-row header_Name" *ngIf = "roleId ==  2 || roleId == 3 || roleId == 4">
    <a class="navbar-brand" routerLinkActive="active-link" routerLink="/apps/reports/report">
      <span class="brand-logo">
        Reports
      </span>
    </a>
  </div>
  <div class="nav navbar-nav flex-row header_Name" *ngIf = "isAdmin == 1">
    <a class="navbar-brand" routerLinkActive="active-link" routerLink="dashboard/cfa-expense/admin-master">
      <span class="brand-logo">
        Admin Masters
      </span>
    </a>
  </div>
  </div>
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig?.layout?.menu?.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Bookmark -->
    <app-navbar-bookmark></app-navbar-bookmark>
    <!--/ Bookmark -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container *ngIf="this.currentUser">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder"
              >Logged in : <span style="color: royalblue;">{{ this.currentUser?.username | uppercase }}</span></span
            ><span class="user-status font-weight-bolder">Role : 
               <span *ngIf=" this.currentUser?.role == 'GCPL'" style="color: royalblue;">RLE</span>
               <span *ngIf=" this.currentUser?.role == 'GCPL HEAD'" style="color: royalblue;">RLM</span>
               <span *ngIf=" this.currentUser?.role != 'GCPL HEAD' && this.currentUser?.role != 'GCPL'" style="color: royalblue;">{{ this.currentUser?.role | uppercase }}</span>
              </span>
          </div>
          <span class="avatar"
            ><img
              class="round"
              src="assets/images/logo/default.jpg"
              alt="avatar"
              height="40"
              width="40" /><span class="avatar-status-online"></span
          ></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/pages/authentication/forgot-password-v1']"
          >Change Password</a
        > 
        <a ngbDropdownItem (click)="logout(this.currentUser?.id)"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
